const editorBasepath = '/app/presentation';
const dashboardBasepath = '/app';
const contentBasepath = '';
const integrationsBasepath = '/_integrations';
const authBasepath = '/auth';

export function shouldRouteInternally(basePath: string, path: string) {
  // Going from content to somewhere else.
  // If adding voting it should go here.
  if (
    (basePath === contentBasepath &&
      (path.startsWith('/app') || path.startsWith(integrationsBasepath))) ||
    path.startsWith(authBasepath)
  ) {
    return false;
  }

  // Do we need another case for auth? Probably.
  // Since we need to check another basePath in all cases it shoud be rewritten.
  // Will break auth/reset_password.
  // if (basePath === authBasepath && !path.startsWith(authBasepath)) {
  //   return false;
  // }

  // Going from editor to somewhere else.
  if (basePath === editorBasepath && !path.startsWith(editorBasepath)) {
    return false;
  }

  // Going from dashboard somewhere else.
  if (
    basePath === dashboardBasepath &&
    (!path.startsWith(dashboardBasepath) || path.startsWith(editorBasepath))
  ) {
    return false;
  }

  // Going from integrations somewhere else.
  if (
    basePath === integrationsBasepath &&
    !path.startsWith(integrationsBasepath)
  ) {
    return false;
  }

  return path.startsWith(basePath);
}
